import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';


import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { NgxEditorModule } from 'ngx-editor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ExcelService } from './global/Services/excel.service';
import { CryptoService} from './global/Services/crypto.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home/home.component';

import { HeaderComponent } from './home/header/header.component';
import { FooterComponent } from './home/footer/footer.component';
import { AppRoutingModule } from './/app-routing.module';

import { CalendarModule } from 'primeng/calendar'
import { ProgressComponent } from './global/progress/progress.component';
import { DialogComponent } from './global/dialog/dialog.component';
import { getSpanishPaginatorIntl } from './global/model/global';
import { DateFormatPipe } from './global/Services/date-format.pipe';

import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProgressComponent,
    DialogComponent,
    DateFormatPipe
    
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CalendarModule,
    NgxEditorModule,
    TooltipModule.forRoot()

  ],
  providers: [AppComponent, 
              {provide: LocationStrategy, useClass: HashLocationStrategy},
              {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()},
              ExcelService,
              CryptoService,
              DateFormatPipe
            ],
            
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }
