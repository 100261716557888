import { paramKey } from 'blocking-proxy/built/lib/webdriver_commands';
import { inherits } from 'util';
import { extend } from 'highcharts';
import { DateFormatPipe } from '../Services/date-format.pipe';

export class Usuario {
    Id: number;
    NombreCompleto: string;
    Cargo: string;
    Mail: string;
    Pass: string;
    EsAdmin: boolean;

    MailJefatura: string;
    NombreJefatura: string;
    CargoJefatura: string;

}

export class Parametro {
    Id: number;
    Nombre: string;
    Descripcion: string;
    TieneDescripcion: string;
    Padre: Parametro;
    Vigente: boolean;
    TipoParametroId: number
}
export class TipoParametro {
    Id: number;
    Nombre: string;
    TienePadre: boolean;
    TipoPadre: TipoParametro;
    IdTipoPadre: number;
    Vigente: boolean;
}

export class Encabezado {
    Id: number;
    Tipo: any;
    // Tipo: TipoFormulario;
    NombreCompletoUsuario: string;
    CargoUsuario: string;
    MailUsuario: string;

    NombreEmpresa: string;
    IdEmpresa: number;

    NombreDirectorArea: string;

    NombreGerencia: string;

    NombreCompletoJefatura: string;
    CargoJefatura: string;
    MailJefatura: string;
    Detalle: any;
    Destinatarios: Destinatario[];
    VistoJefatura: boolean;
    VistoCompliance: boolean;
    SemaforoJef: string;
    SemaforoCom: string;
    AutorizacionJefatura: Autorizacion;
    AutorizacionCompliance: Autorizacion;
    ComentarioJefatura: string;
    ComentarioCompliance: string;
    FechaRegistro: Date;
}

export class ConflictoInteres {
    Id: number;
    Encabezado: Encabezado;
    Operacion: string;
    Tipo: string;
    OtroTipo: string;
    Relacion: string;
    OtraRelacion: string;
    Descripcion: string;
    InformacionAdicional: string;

}

export class Invitacion {
    Id: number;
    Encabezado: Encabezado;

    Persona: string;
    Institucion: string;
    Empresa: string;
    Fecha: Date;
    Lugar: string;
    Motivo: string;
    Asistentes: Asistente[];
    Participa: string;
    Valor: string;

    Anterior: string;
    MotivoInvitacionAnterior: string;
    MontoInvitacionAnterior: string;

    InformacionAdicional: string;
}

export class Regalo {
    Id: number;
    Encabezado: Encabezado;


    Tipo: string;
    Fecha: string;
    Persona: string;
    Institucion: string;
    Empresa: string;
    Descripcion: string;
    ArchivoAdjunto: ArchivoAdjunto;
    Motivo: string;
    OtroMotivo: string;
    Autorizado: string;
    Valor: string;
    Participa: string;
    Anterior: string;
    FechaAnterior: string;
    MotivoAnterior: string;

    InformacionAdicional: string;
}

export class ReunionFFPP {
    Id: number;
    Encabezado: Encabezado;

    Tipo: string;
    Fecha: string;
    Lugar: string;
    Motivo: string;
    Institucion: string;

    AsistentesAguas: Asistente[];
    AsistentesFFPP: Asistente[];
    OtrosAsistentes: Asistente[];

    Especificacion: string;
    InformacionAdicional: string;
    ArchivoAdjunto: ArchivoAdjunto;

    EnviarStakeHolder: boolean;
    Pioridad: string;
    Percepcion: string;
    InstitucionMRisk: number;
    EspecificacionSimple: string;
}

export class Viaje {
    Id: number;
    Encabezado: Encabezado;
    Fecha: string;
    Motivo: string;
    Persona: string;
    Tipo: string;
    OtroTipo: string;

    Asistentes: Asistente[];

    Participa: string;
    Gastos: GastoViaje[];

    Anterior: string;
    MotivoAnterior: string;
    MontoAnterior: string;
    InformacionAdicional: string;
}

export class ArchivoAdjunto {
    Id: number;
    IdEncabezado: number;
    Nombre: string;
    Tipo: string;
    Contenido: string;
}

export class Asistente {
    Id: number;
    NombreCompleto: string;
    Empresa: string;
    Cargo: string;
    Mail: string;
}

export class GastoViaje {
    Id: number;
    IdEncabezado: number;
    Concepto: string;
    Proveedor: boolean;
    GrupoAguas: boolean;
}

export class Filtro {
    FechaDesde: Date;
    FechaHasta: Date;
    Empresas: string[];
    Direcciones: string[];
    EsAdmin: boolean = false;
}

export class chartFiltro{
    Series: string[];
    Gerencia: string;
    Direccion: string[];
    Anho: string;
    MesInicio: number;
    MesFin: number;
    constructor(private anho: string)
    {
        this.Series = [];
        this.Gerencia = undefined;
        this.Direccion = [];
        this.Anho = this.anho;
        this.MesInicio = 1;
        this.MesFin = 12;
    }
}

export class Destinatario {
    Mail: string;
    public Destinatario(mail: string) {
        this.Mail = mail;
    }
}
export class TipoFormulario {
    Tipo: string;
}

export class ExportType {
    IdEncabezado: number;
    NombreCompletoUsuario: string;
    FechaAutorizacionJefatura: string;
    DiasJefatura: number;
    FechaAutorizacionCompliance: string;
    DiasCompliance: number;
    SemaforoCom: string;
    SemaforoJef: string;
    CargoUsuario: string;
    MailUsuario: string;
    NombreEmpresa: string;
    NombreDirectorArea: string;
    NombreGerencia: string;
    NombreCompletoJefatura: string;
    CargoJefatura: string;
    MailJefatura: string;
    VistoJefatura: string;
    VistoCompliance: string;
    FechaRegistro: string;
    constructor(){
        this.IdEncabezado = 0;
        this.NombreCompletoUsuario = "";
        this.CargoUsuario = "";
        this.MailUsuario= "";
        this.NombreEmpresa = "";
        this.NombreDirectorArea = "";
        this.NombreGerencia = "";
        this.NombreCompletoJefatura = "";
        this.CargoJefatura = "";
        this.MailJefatura = "";
        this.SemaforoJef = "";	
        this.SemaforoCom = "";	
        this.VistoJefatura = "";	
        this.VistoCompliance = "";	
        this.FechaRegistro = "";	

        this.FechaAutorizacionJefatura = "";
        this.DiasJefatura = 0;
        this.FechaAutorizacionCompliance = "";        
        this.DiasCompliance = 0;

    }
}

export class RegistroLectura {
    Id: number;
    IdEncabezado: number;
    Cantidad: number;
    Mail: string;
    Origen: string;
}

export class Autorizacion {
    Id: number;
    IdEncabezado: number;
    Mail: string;
    Destinatarios: Destinatario[];
    Rol: string;
    Respuesta: string;
}

export class Administrador {
    UserId: number;
    Privilegios: Privilegio[];
    Direcciones: Parametro[];
    Usuario: Usuario;
}

export class Privilegio {
    Empresa: Parametro;
    PuedeAutorizar: boolean;
    PuedeVisualizar: boolean;
    PuedeExportar: boolean;
    PuedeMantener: boolean;
    PuedeVerIndicadores: boolean;
    UsuarioModifica: number;
}

export class Indicadores {
    ConflictoInteres: IndicadorConflicto[];
    Invitacion: IndicadorInvitacion[];
    Regalos: IndicadorRegalos[];
    Reunion: IndicadorReunion[];
    Viajes: IndicadorViajes[];
}

export class Unidad {
    Nombre: string;
    ConflictoInteres: number;
    Invitacion: number;
    Regalo: number;
    ReunionFFPP: number;
    Viaje: number;
}

export class IndicadorEncabezado{
    IdEncabezado: number;
    IdFormulario: number;
    FechaRegistro: Date;
    TipoFormulario: string;
    Empresa: string;
    Direccion: string;
    Gerencia: string;
    Estado: string;
}

export class IndicadorConflicto extends IndicadorEncabezado{
    TipoDetalle: string;
}

export class IndicadorInvitacion extends IndicadorEncabezado{
    TramoMonto: string;
}

export class IndicadorRegalos extends IndicadorEncabezado{
    TipoDetalle: string;
    TramoMonto: string;
}

export class IndicadorReunion extends IndicadorEncabezado{
    TipoDetalle: string;
    Institucion: string;
}

export class IndicadorViajes extends IndicadorEncabezado{
    TipoDetalle: string;
}

export class ChartBarStacked{
    Titulo: string;
    Subtitulo:string;
    Categorias: string[];
    Series: SerieBarStacked[];
    constructor(){
        this.Titulo = '';
    this.Series = [];
    this.Categorias = [];
    // for (var i = 5; i > 0; i--) { this.Categorias.push('') }
    for (var i = 5; i > 0; i--) { this.Series.push(new SerieBarStacked('', '')) }
    }
}

export class SerieBarStacked{
    name: string;
    data: number[];
    color: string;

    constructor(name: string, color: string, lenght: number = 0) {
        this.name = name;
        this.color = color;
        this.data = [];
        if(lenght != 0){
            for(var i = lenght; i > 0; i--){this.data.push(0)}
        }
    }
}

export class ChartPie{
    Titulo: string;
    Subtitulo: string;
    Catergorias: string[];
    Series: SeriePie[];
}

export class SeriePie{
    name: string;
    data: DataPie[];
    color: string;

    constructor(name: string, color: string) {
        this.name = name;
        this.color = color;
        this.data = [];
    }
}
export class DataPie{
    name: string;
    y: number;
    color: string;
    sliced: boolean;
    selected : boolean;

    constructor(name: string, color: string, sliced: boolean = false, selected: boolean = false) {
        this.name = name;
        this.color = color;
        this.y = 0;
        this.sliced = sliced;
        this.selected = selected;
    }

}