import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { TipoFormulario, Destinatario, ExportType, Autorizacion } from '../model/Model';
import { longStackSupport } from 'q';
import { DateFormatPipe } from './date-format.pipe';
@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  Key: string;
  IV: string;

  constructor(public pipe: DateFormatPipe) {

    this.Key = CryptoJS.enc.Utf8.parse('7061737323313233');
    this.IV = CryptoJS.enc.Utf8.parse('7061737323313233');
  }

  public EncryptString(string: string) {
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), this.Key,
      {
        keySize: 128 / 8,
        iv: this.IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
  }
  public EncryptObjects(Objeto: any): any {

    for (var a in Objeto) {
      if (Objeto[a] instanceof TipoFormulario) continue;
      if (Objeto[a] instanceof Destinatario) continue;


      if (a === 'EnviarStakeHolder' ||
          a === 'IdEmpresa') {
        Objeto[a] = Objeto[a]
      }

      if (a == 'ArchivoAdjunto') {
        Objeto[a] = this.EncryptObjects(Objeto[a])
      }
      if (a == 'Asistentes' ||
        a == 'AsistentesAguas' ||
        a == 'AsistentesFFPP' ||
        a == 'OtrosAsistentes') {
        var lista = Objeto[a];
        Objeto[a] = []
        for (var asistente in lista) {
          Objeto[a].push(this.EncryptObjects(lista[asistente]));
        }
      }
      if (a == 'Encabezado') {
        Objeto[a] == this.EncryptObjects(Objeto[a])
      }

      else {
        if (typeof Objeto[a] == 'string' ||
          a == 'MontoInvitacionAnterior' ||
          a == 'Fecha' ||
          a == 'FechaAnterior' ||
          a == 'OtroMotivo'
        ) {
          var algo = this.EncryptString(Objeto[a]);
          Objeto[a] = algo.toString()
        }
      }
    }
    return Objeto;
  }

  public DecryptObjects(Objeto): any {
    var res = new Object();
    for (var a in Objeto) {
      if (Objeto[a] == null) continue;
      if (a == 'Destinatario') continue;
      if(a == 'InstitucionMRisk') Objeto[a] = this.EncryptString(Objeto[a]).toString();
      var d;

      if (a == 'FechaRegistro') {
        if (Objeto[a].toString().includes("-")) {
          let fecha = Objeto[a].toString().substring(0, 10).split('-')
          res[a] = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
        } else {
          res[a] = Objeto[a]
        }
        continue;
      }
      if (a == 'Fecha' ||
        a == 'FechaAnterior') {
        if (Objeto[a] != null) {
          var f = this.pipe.transform(this.DecryptString(Objeto[a]).toString(CryptoJS.enc.Utf8));
          if (f != null) {

            res[a] = f.toString();
          }
        }
      }

      if (typeof Objeto[a] == 'string'
      && a != 'FechaAutorizacionCompliance'
      && a != 'FechaAutorizacionJefatura'
      ) {
        
        d = this.DecryptString(Objeto[a]).toString(CryptoJS.enc.Utf8);
      }
      if (a == 'VistoJefatura' ||
        a == 'VistoCompliance' ||
        a == 'Gastos' ||
        a == 'Id' ||
        a == 'IdEncabezado' ||
        a == 'SemaforoJef' ||
        a == 'SemaforoCom' ||
        a == 'AutorizacionJefatura' ||
        a == 'AutorizacionCompliance' ||
        a == 'DiasCompliance' ||
        a == 'DiasJefatura' ||
        a == 'FechaAutorizacionCompliance' ||
        a == 'FechaAutorizacionJefatura' ||
        a == 'EnviarStakeHolder' ) {
        d = Objeto[a];
        
      }

      if (a == 'Detalle' || a == 'Encabezado') {
        d = this.DecryptObjects(Objeto[a]);
      }
      if (a == 'ArchivoAdjunto') {
        d = this.DecryptObjects(Objeto[a])
      }
      if (a == 'Asistentes' ||
        a == 'AsistentesAguas' ||
        a == 'AsistentesFFPP' ||
        a == 'OtrosAsistentes') {
        var lista = Objeto[a]
        Objeto[a] = []
        for (var asistente in lista) {
          Objeto[a].push(this.DecryptObjects(lista[asistente]))
        }
        d = Objeto[a]
      }

      if (a == 'Tipo' && Objeto[a].hasOwnProperty('Tipo')) {
        d = Objeto[a].Tipo;
      }

      res[a] = d;
    };
    return res;


  }
  public DecryptString(word: string) {
    return CryptoJS.AES.decrypt(word, this.Key, {
      keySize: 128 / 8,
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
  }

  public DecryptStringWord(word: string): string {
    return CryptoJS.AES.decrypt(word, this.Key, {
      keySize: 128 / 8,
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

  public createExportType(Objeto: any): ExportType {

    var nuevo = new ExportType();

    var SupObj = this.DecryptObjects(Objeto)


    var SubObj = this.DecryptObjects(Objeto['Encabezado']);
    //console.log(SubObj);

    var Asistentes = Objeto['Asistentes'];

    var AsistenteAguas = Objeto['AsistentesAguas'];
    var AsistenteFFPP = Objeto['AsistentesFFPP'];
    var OtrosAsistentes = Objeto['OtrosAsistentes'];

    for (let sub in SubObj) {
      switch (sub) {
        case 'Id': {
          nuevo['IdEncabezado'] = SubObj[sub];
          break;
        }
        case 'Tipo': {
          break;
        }
        case 'VistoCompliance': {
          nuevo[sub] = SubObj[sub] == true ? 'Si' : 'No';

          break;

        }
        case 'VistoJefatura': {
          nuevo[sub] = SubObj[sub] == true ? 'Si' : 'No';

          break;
        }

        case 'AutorizacionCompliance': {
          break;
        }
        case 'AutorizacionJefatura': {
          break;
        }
        // case 'DiasCompliance':{

        //   console.log(SubObj[sub]);
        //   nuevo[sub] = SubObj[sub]
        //   break;
        // }
        default: {
          nuevo[sub] = SubObj[sub];

          break;
        }
      }
    }

    for (let sup in SupObj) {


      if (
        // sup == 'Encabezado' ||
        sup == 'Asistentes' ||
        sup == 'AsistentesAguas' ||
        sup == 'AsistentesFFPP' ||
        sup == 'OtrosAsistentes') {
        nuevo[sup] = SupObj[sup].length;
      }
      else if (sup == 'Encabezado') {

      } else {
        nuevo[sup] = SupObj[sup]
      }
    }

    let i = 0;
    for (let a in Asistentes) {
      i++;
      var asistente = Asistentes[a];
      for (let b in asistente) {
        nuevo['Asistente' + i + '-' + b] = asistente[b]
      }
    }
    i = 0;
    for (let a in AsistenteAguas) {
      i++;
      var asistente = AsistenteAguas[a];
      for (let b in asistente) {

        nuevo['AsistenteGrupoAguas' + i + '-' + b] = asistente[b]
      }
    }
    i = 0;
    for (let a in AsistenteFFPP) {
      i++;
      var asistente = AsistenteFFPP[a];
      for (let b in asistente) {
        nuevo['AsistenteFFPP' + i + '-' + b] = asistente[b]
      }
    }
    i = 0;
    for (let a in OtrosAsistentes) {
      i++;
      var asistente = OtrosAsistentes[a];
      for (let b in asistente) {
        nuevo['OtroAsistente' + i + '-' + b] = asistente[b]
      }
    }

    return nuevo;
  }

  // public Prueba() {
  //   var key = CryptoJS.enc.Utf8.parse('7061737323313233');
  //   var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
  //   var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("It works"), key,
  //     {
  //       keySize: 128 / 8,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     });

  //   var decrypted = CryptoJS.AES.decrypt('4fBtd3mEZ+fqMLOonHywkw==', key, {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });

  //   //console.log('Encrypted :' + encrypted);
  //   //console.log('Key :' + encrypted.key);
  //   //console.log('Salt :' + encrypted.salt);
  //   //console.log('iv :' + encrypted.iv);
  //   //console.log('Decrypted : ' + decrypted);
  //   //console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));
  // }
}
